import React from "react";
import styled from "styled-components";
import SocialLinksSmall from "./SocialLinksSmall";

const SocialLinksStyles = styled.div`
  h4 {
    margin: 0.5rem 0;
    font-size: 0.8rem;
    font-weight: bold;
  }
  ul {
    a {
      color: gray;
      text-decoration: underline;
    }
  }
  .social-links {
    .social__extra {
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: 768px) {
      .social__extra {
        display: none;
      }
    }
  }
`;

const SocialLinks = ({ className }) => {
  return (
    <SocialLinksStyles className={`${className} column is-4`}>
      <SocialLinksSmall></SocialLinksSmall>
      <div className="is-hidden-mobile social__extra">
        <h4 className="has-text-centered">See what others are saying</h4>
        <ul className="has-text-centered">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.tripadvisor.co.uk/Hotel_Review-g641710-d3332239-Reviews-Rwakobo_Rock-Lake_Mburo_National_Park_Western_Region.html"
            >
              Trip Advisor
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://thepearlguide.co.ug/stay/rwakobo-rock/"
            >
              The Pearl Guide
            </a>
          </li>
        </ul>
      </div>
    </SocialLinksStyles>
  );
};

export default SocialLinks;
