import React from "react";
import Styled from "styled-components";

const ContactBannerStyles = Styled.div`
    background: rgba(236, 236, 236, 0.81);
    padding: 20px;
  `;
const ContactBanner = () => {
  return (
    <ContactBannerStyles className="email-banner">
      We'll usually be in touch within 12 hours. If you don't hear back from us,
      please check your email spam folder, email us at{" "}
      <a href="mailto:info@rwakoborock.com">info@rwakoborock.com</a> or call us
      on <a href="tel:+256 755 211771">+256 755 211771</a>.
    </ContactBannerStyles>
  );
};

export default ContactBanner;
