import React from "react";
import styled from "styled-components";
import facebookLogo from "../img/social/facebook-logo.png";
import tripAdvisorLogo from "../img/social/trip-advisor-logo.jpg";
const SocialLinksSmallStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  @media screen and (max-width: 768px) {
    justify-content: flex-end;
  }
  > a {
    width: 50px;
    height: 50px;
    display: block;
    color: transparent;
    text-overflow: hidden;
  }
`;

const SocialLinksSmall = () => {
  return (
    <SocialLinksSmallStyle className="social-links">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/Rwakobo-Rock-210100835768727/"
        className="facebook-link"
        style={{
          background: `url(${facebookLogo}) center center no-repeat`,
          backgroundSize: `contain`
        }}
      >
        Facebook
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.tripadvisor.co.uk/Hotel_Review-g641710-d3332239-Reviews-Rwakobo_Rock-Lake_Mburo_National_Park_Western_Region.html"
        className="trip-advisor-link"
        style={{
          background: `url(${tripAdvisorLogo}) center center no-repeat`,
          backgroundSize: "contain",
          borderRadius: "6px",
          border: "1px solid transparent"
        }}
      >
        Trip Advisor
      </a>
    </SocialLinksSmallStyle>
  );
};

export default SocialLinksSmall;
