import React, { useState } from "react";
import Layout from "../../components/Layout";
import SocialLinks from "../../components/SocialLinks";
import ContactBanner from "../../components/ContactBanner";

const Index = () => {
  const [messageSent, setMessageSent] = useState(false);
  const [messageHasError, setMessageHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const emptyForm = () => {
    resetUserName();
    resetUserEmail();
    resetMessage();
  };

  const checkValidity = () => {
    return userName.length > 0 && userEmail.length > 0 && message.length > 0;
  };

  const useInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    return {
      value,
      setValue,
      reset: () => setValue(""),
      bind: {
        value,
        onChange: event => {
          const value = event.target.value;
          setValue(event.target.value);
        }
      }
    };
  };

  const handleSubmit = async e => {
    e.persist();
    e.preventDefault();
    const form = e.target;

    if (!checkValidity()) {
      return;
    }

    setIsLoading(true);

    // Don't submit the form is the bot-field is filled, but don't pass this feedback to the UI.
    if (botField.length) {
      setMessageSent(true);
      setMessageHasError(false);
      emptyForm();
      setIsLoading(false);
      return;
    }

    const formData = JSON.stringify({
      name: userName,
      email: userEmail,
      message: message.split("\n").join("<br/>"),
      ses_email: "contact-form@rwakoborock.com",
      subject: "New email from site"
    });

    await fetch(
      // form handled on AWS Lambda
      "https://tmjgec6e73.execute-api.us-east-1.amazonaws.com/dev/static-site-mailer",
      {
        method: "post",
        body: formData
      }
    )
      .then(response => {
        const responseBody = response.json();
        if (response.status === 200) {
          setMessageSent(true);
          setMessageHasError(false);
          emptyForm();
          setIsLoading(false);
        } else {
          setMessageSent(false);
          setIsLoading(false);
          setErrorMessage(responseBody.message);
          setMessageHasError(true);
        }
      })
      // .then(() => navigate(form.getAttribute("action")))
      .catch(e => {
        // If there is an error reaching AWS (e.g. CORS error when executed locally) this will catch it
        setIsLoading(false);
        setMessageHasError(true);
        setErrorMessage(e.message);
        return;
      });
  };

  const {
    value: botField,
    bind: bindBotField,
    reset: resetBotField
  } = useInput("");
  const {
    value: userName,
    bind: bindUserName,
    reset: resetUserName
  } = useInput("");
  const {
    value: userEmail,
    bind: bindUserEmail,
    reset: resetUserEmail
  } = useInput("");
  const { value: message, bind: bindMessage, reset: resetMessage } = useInput(
    ""
  );

  return (
    <Layout>
      <section className="section">
        <div className="container">
          <ContactBanner />
          <div className="columns">
            <div className="content column is-8">
              <div className="title-wrapper">
                <h1 className="title">Contact & Bookings</h1>
              </div>
              <p>
                We welcome your thoughts, comments or personal
                contact requests. Please use the contact form below to get in
                touch. Or you can phone us on{" "}
                <a href="tel:+256 755 211771">+256 755 211771</a> or email us at{" "}
                <a href="mailto:info@rwakoborock.com">info@rwakoborock.com.</a>
              </p>
              <form
                name="contact"
                method="post"
                action="/contact-bookings/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" {...bindBotField} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"userName"}>
                    Your name (required)
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"text"}
                      name={"userName"}
                      id={"userName"}
                      required={true}
                      {...bindUserName}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"userEmail"}>
                    Your Email (required)
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"email"}
                      name={"userEmail"}
                      id={"userEmail"}
                      required={true}
                      {...bindUserEmail}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"message"}>
                    Your Message
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={"message"}
                      id={"message"}
                      required={true}
                      {...bindMessage}
                    />
                  </div>
                </div>
                <div className="field">
                  <button
                    className="button is-link"
                    type="submit"
                    disabled={isLoading ? `disabled` : false}
                  >
                    {isLoading ? "Sending" : "Send"}
                  </button>
                </div>
              </form>
              <div className="form-message">
                {messageSent ? (
                  <p style={{ paddingTop: 20 }} className="">
                    Message sent! We'll usually be in touch within 12 hours. If
                    you don't hear back from us, please check your email spam
                    folder, email us at{" "}
                    <a href="mailto:info@rwakoborock.com">
                      info@rwakoborock.com
                    </a>{" "}
                    or call us on{" "}
                    <a href="tel:+256 755 211771">+256 755 211771</a>.
                  </p>
                ) : (
                  ""
                )}
                {messageHasError ? (
                  <p className="is-family-monospace">
                    There was an error! {errorMessage}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <SocialLinks></SocialLinks>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
